<template>
    <TablePlaceholder :data="administrators">
        <template #default="{ items, count }">
            <LTable
                ref="table"
                :data="items"
                :loading="isLoading"
                :count="count"
                backend-pagination
                virtual-scroll
                backend-sorting
                query-pagination
                with-options
                @update="GET_ADMINISTRATORS"
                @sort="updateSortings">
                <TableColumn
                    v-slot="{ row }"
                    sortable
                    field="intId"
                    :label="$t(`admin.users.administrators.table.labels.id`)">
                    {{ formatEmptyString(row.intId) }}
                </TableColumn>

                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.administrators.table.labels.name`)">
                    <AdminOutput
                        :admin="{ intId: row.intId, login: row.login, id: row.id, avatar: row.avatar }"
                        class="mr-1"
                        login
                        avatar>
                    </AdminOutput>

                    <PortraitIcon :user-id="row.id"></PortraitIcon>

                    <LTooltip
                        v-if="row.activity.isBlocked"
                        :label="$t(`admin.users.administrators.filters.values.blocked`)"
                        position="is-bottom"
                        type="is-dark">
                        <b-icon
                            class="has-text-light"
                            icon="ban"
                            size="is-small">
                        </b-icon>
                    </LTooltip>

                    <LTooltip
                        v-if="!row.activity.isConfirmed"
                        :label="$t(`admin.users.administrators.filters.values.emailUnConfirmedIcon`)"
                        position="is-bottom"
                        type="is-dark">
                        <b-icon
                            class="has-text-light line-through"
                            icon="envelope"
                            size="is-small">
                        </b-icon>
                    </LTooltip>

                    <div
                        v-if="row.name"
                        class="mt-1">
                        <small>{{ row.name }}</small>
                    </div>
                </b-table-column>

                <b-table-column
                    v-slot="{ row: { contact: { email } } }"
                    :label="$t(`admin.users.administrators.table.labels.email`)">
                    <ContactOutput
                        :value="email"
                        type="email">
                    </ContactOutput>
                </b-table-column>

                <b-table-column
                    v-slot="{ row: { contact: { telegram } } }"
                    :label="$t(`admin.users.administrators.table.labels.telegram`)">
                    <ContactOutput
                        :value="telegram"
                        type="telegram">
                    </ContactOutput>
                </b-table-column>

                <b-table-column
                    v-slot="{ row: { contact: { skype } } }"
                    :label="$t(`admin.users.administrators.table.labels.skype`)">
                    <ContactOutput
                        :value="skype"
                        type="skype">
                    </ContactOutput>
                </b-table-column>

                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.administrators.table.labels.countries`)">
                    <Countries
                        :items="row.countries"
                        compact
                        tooltip-position="is-top">
                    </Countries>
                </b-table-column>

                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.administrators.table.labels.regDate`)">
                    {{ formatEmptyString(moment(row.activity.registeredAt)) }}
                </b-table-column>

                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.administrators.table.labels.lastVisit`)">
                    <div class="flex is-flex-direction-column is-align-items-start">
                        <span>
                            {{ momentWithEmpty( row.lastVisit) }}
                        </span>

                        <span
                            v-if="row.lastVisit"
                            class="has-text-grey has-text-centered"
                            style="font-size: 12px">
                            {{ momentFrom(row.lastVisit) }}
                        </span>
                    </div>
                </b-table-column>

                <b-table-column v-slot="{ row }">
                    <DropdownOptions
                        rounded
                        size="is-small">
                        <Permissions :permissions="['ADMIN.EDIT']">
                            <DropdownOption
                                v-if="!isSelf(row.id)"
                                icon="edit"
                                type="is-warning is-light"
                                @click="onEditAdmin(row)">
                                {{ $t(`admin.users.administrators.table.buttons.edit`) }}
                            </DropdownOption>
                        </Permissions>

                        <DropdownOption
                            v-if="!isSelf(row.id) && isForceLogout(false)"
                            :custom-icon="logoutIcon"
                            type="is-danger is-light"
                            @click="forceLogoutUser(row)">
                            {{ $t(`common.entity.users.buttons.logout`) }}
                        </DropdownOption>

                        <DropdownOption
                            v-if="isBlockOrUnblock(row.activity)"
                            :icon="blockButtonIcon(row.activity.isBlocked)"
                            :type="blockButtonType(row.activity.isBlocked)"
                            @click="onToggleBlock(row)">
                            <template v-if="row.activity.isBlocked">
                                {{ $t(`admin.users.administrators.table.buttons.unblock`) }}
                            </template>
                            <template v-else>
                                {{ $t(`admin.users.administrators.table.buttons.block`) }}
                            </template>
                        </DropdownOption>

                        <Permissions :permissions="['ADMIN.ADD_OR_EDIT_PERMISSION']">
                            <DropdownOption
                                icon="user-shield"
                                type="is-warning is-light"
                                @click="toggleModalRole(row)">
                                {{ $t(`admin.users.administrators.table.buttons.roles`) }}
                            </DropdownOption>
                        </Permissions>

                        <DropdownOption
                            v-if="!row.activity.isConfirmed"
                            icon="envelope"
                            type="is-success is-light"
                            @click="resendEmailByAdmin(row.id)">
                            {{ $t(`common.mailConfirmation.resendEmail.resend`) }}
                        </DropdownOption>
                    </DropdownOptions>

                    <Permissions :permissions="['ADMIN.IMPERSONATING']">
                        <LTooltip
                            :label="$t(`admin.users.administrators.table.buttons.signIn`)"
                            position="is-left"
                            type="is-success">
                            <b-button
                                icon-left="sign-in-alt"
                                icon-pack="fas"
                                :disabled="isSelf(row.id) || asAdmin"
                                :loading="isLoadingLoginAsAdmin && localAdminId === row.id"
                                rounded
                                size="is-small"
                                type="is-success is-light"
                                @click="singInAsManager(row)">
                            </b-button>
                        </LTooltip>
                    </Permissions>
                </b-table-column>
            </LTable>

            <AdministratorsAddRoleModal
                v-if="modalAddRole"
                :admin="modalAddRoleAdmin"
                :is-active.sync="modalAddRole"
                @update="update">
            </AdministratorsAddRoleModal>
        </template>
    </TablePlaceholder>
</template>

<script>
  import AdministratorsAddRoleModal from "@/components/Admin/Users/AdministratorsTab/AdministratorsAddRoleModal";
  import TableOptionsMixin from "@/components/Admin/Users/TableOptionsMixin";
  import DropdownOption from "@/components/Common/DropdownOptions/DropdownOption";
  import DropdownOptions from "@/components/Common/DropdownOptions/DropdownOptions";
  import PortraitIcon from "@/components/Common/Tooltip/PortraitIcon.vue";
  import AdminOutput from "@/components/Common/Output/AdminOutput.vue";
  import Countries from "@/components/Common/Lists/Countries.vue";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import Permissions from "@/components/Common/Permissions.vue";
  import { TableVuex } from "@core/mixins";
  import {
    ADMIN_LOGIN_AS_ADMINISTRATOR,
    RESEND_EMAIL_BY_ADMIN,
    UNBLOCK_ADMINISTRATOR,
    BLOCK_ADMINISTRATOR,
    GET_ADMINISTRATORS,
    SET_EMPTY,
    FORCE_LOGOUT_BY_ADMIN, GET_USER_PERMISSIONS
  } from "@core/store/action-constants";
  import {
    SET_ADMINISTRATORS_EDIT_ID,
    SET_ADMINISTRATORS_EDIT_FORM,
    SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE,
    UPDATE_ADMINISTRATORS_SORTS
  } from "@core/store/mutation-constants";
  import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
  import LTable from "@/components/Common/LTable";
  import { hasPermissions } from "@core/mixins/permissions";
  import logoutIcon from "@/assets/logout.svg";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import { momentFrom } from "@/filters";
  import { useBadges } from "@/stores/common/badgesStore";
  import ContactOutput from "@/components/Common/Output/ContactOutput.vue";

  export default {
    name: "AdministratorsTable",
    mixins: [TableOptionsMixin, TableVuex],
    components: {
      ContactOutput,
      AdministratorsAddRoleModal,
      TablePlaceholder,
      DropdownOptions,
      DropdownOption,
      PortraitIcon,
      AdminOutput,
      Countries,
      LTable,
      Permissions
    },

    data () {
      return {
        modalAddRole: false,
        localAdminId: null,
        modalAddRoleAdmin: {},
        logoutIcon
      };
    },

    setup () {
      return {
        badgesStore: useBadges()
      };
    },

    computed: {
      ...mapState({
        hasBlocked: ({ auth }) => hasPermissions(["ADMIN.BLOCK"], auth.userPermissions),
        hasUnblocked: ({ auth }) => hasPermissions(["ADMIN.UNBLOCK"], auth.userPermissions)
      }),

      ...mapState("admin/users/administrators", {
        administrators: state => state.administrators
      }),

      ...mapState("admin/profile", {
        selfId: ({ user }) => user.id
      }),

      ...mapGetters(["asAdmin"]),

      ...mapGetters("userLogout", ["isForceLogout"]),

      isLoading () {
        return this.$wait(`admin/users/administrators/${ GET_ADMINISTRATORS }`);
      },

      isLoadingLoginAsAdmin () {
        return this.$wait(ADMIN_LOGIN_AS_ADMINISTRATOR);
      },

      isPaginated () {
        return this.administrators.count > this.perPage;
      }
    },

    methods: {
      momentFrom,
      ...mapActions("admin/users/administrators", [
        GET_ADMINISTRATORS,
        BLOCK_ADMINISTRATOR,
        UNBLOCK_ADMINISTRATOR,
        RESEND_EMAIL_BY_ADMIN
      ]),

      ...mapMutations("admin/users/administrators", [
        UPDATE_ADMINISTRATORS_SORTS
      ]),

      ...mapMutations("admin/users/administrators/edit", [
        SET_ADMINISTRATORS_EDIT_ID,
        SET_ADMINISTRATORS_EDIT_FORM,
        SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE
      ]),

      ...mapMutations([GET_USER_PERMISSIONS]),

      ...mapActions({
        ADMIN_LOGIN_AS_ADMINISTRATOR,
        FORCE_LOGOUT_BY_ADMIN: `userLogout/${ FORCE_LOGOUT_BY_ADMIN }`
      }),

      ...mapMutations("admin/profile", {
        SET_EMPTY
      }),

      updateSortings (sort, order) {
        if (sort && order) {
          this.UPDATE_ADMINISTRATORS_SORTS({ sort, order });
          this.update();
        }
      },

      async singInAsManager ({ id }) {
        try {
          this.localAdminId = id;
          await this.ADMIN_LOGIN_AS_ADMINISTRATOR(id);
          this.SET_EMPTY();
          this.GET_USER_PERMISSIONS(null);
        } catch {
          this.localAdminId = null;
        }
      },

      isBlockOrUnblock ({ isBlocked }) {
        return !isBlocked && this.hasBlocked || isBlocked && this.hasUnblocked;
      },

      coloredStatus (status) {
        return status ? "is-success" : "is-danger";
      },

      toggleModalRole (admin) {
        if (!this.modalAddRole) {
          this.modalAddRoleAdmin = admin;
          this.modalAddRole = true;
        }
      },

      onToggleBlock ({ id, login: adminLogin, activity: { isBlocked } }) {
        const dataSet = {
          true: { action: this.UNBLOCK_ADMINISTRATOR, name: "unblock" },
          false: { action: this.BLOCK_ADMINISTRATOR, name: "block" }
        };

        this.$buefy.dialog.confirm({
          title: this.$t(`admin.users.administrators.confirm.${ dataSet[isBlocked].name }`),
          confirmText: this.$t("admin.users.administrators.confirm.confirmButtonText"),
          cancelText: this.$t("admin.users.administrators.confirm.cancelButtonText"),
          type: "is-info is-light",
          onConfirm: async () => {
            await actionWithToast(
              dataSet[isBlocked].action(id),
              `admin.users.administrators.messages.${ dataSet[isBlocked].name }`,
              {},
              { adminLogin }
            );
          }
        });
      },

      forceLogoutUser ({ id, login: user }) {
        this.$buefy.dialog.confirm({
          title: this.$t("admin.users.common.confirm.logout.title"),
          message: this.$t("admin.users.common.confirm.logout.subtitle", { user }),
          confirmText: this.$t("admin.users.administrators.confirm.confirmButtonText"),
          cancelText: this.$t("admin.users.administrators.confirm.cancelButtonText"),
          type: "is-info is-light",
          size: "is-small",
          onConfirm: () => actionWithToast(this.FORCE_LOGOUT_BY_ADMIN(id), this.$t("admin.users.common.messages.logout", { user }))
        });
      },

      isSelf (id) {
        return id === this.selfId;
      },

      async resendEmailByAdmin (id) {
        await actionWithToast(this.RESEND_EMAIL_BY_ADMIN(id), "common.mailConfirmation.resendEmail");
      },

      onEditAdmin ({ id, ...rowFields }) {
        this.SET_ADMINISTRATORS_EDIT_FORM({ id, ...rowFields });
        this.SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE(true);
      },

      async update () {
        await this.GET_ADMINISTRATORS();
        await this.badgesStore.GET_BADGES();
      }
    }
  };
</script>

<style lang="scss" scoped>
    .line-through {
        position: relative;

        &:after {
            content: "";
            top: 0;
            left: 0;
            width: 4px;
            height: 120%;
            position: absolute;
            transform: translate(5px, -2px) rotateZ(-45deg);
            background-image: linear-gradient(90deg, #B5B5B5 50%, white 50%);
        }
    }
</style>
