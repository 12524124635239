<template>
    <LTooltip
        v-if="isSelf"
        :label="$t(`common.table.self`)"
        position="is-bottom"
        type="is-info">
        <b-icon
            icon="portrait"
            size="is-small"
            type="is-primary">
        </b-icon>
    </LTooltip>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "PortraitIcon",
    props: {
      userId: {
        type: String,
        default: null
      }
    },

    computed: {
      ...mapState("admin/profile", {
        selfId: ({ user }) => user.id
      }),

      isSelf () {
        return this.userId === this.selfId;
      }
    }
  };
</script>

<style scoped lang="scss">
</style>
