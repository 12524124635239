<template>
    <div>
        <ModalBox
            :is-active.sync="isActive"
            @close="close"
            @submit="onSubmit">
            <template #header>
                <h5 class="title is-5">
                    {{ $t(`admin.users.administrators.modal.${ isEdit ? "edit" : "addAdmin" }.header.title`) }}
                </h5>
            </template>
            <AdminProfile
                :user.sync="localForm"
                :is-edit="isEdit"
                is-countries
                is-description
                is-worked-year
                in-house
                :with-password-edit="!isEdit">
            </AdminProfile>
            <template #footer>
                <div class="container">
                    <div class="columns">
                        <div class="column">
                            <Permissions
                                v-if="isEdit"
                                :permissions="['ADMIN.CHANGE_PASSWORD']">
                                <b-button
                                    class="is-fullwidth is-info is-light"
                                    expanded
                                    @click="openPasswordModal">
                                    {{ $t(`admin.profile.modal.footer.buttons.changePassword`) }}
                                </b-button>
                            </Permissions>
                            <b-button
                                v-else
                                class="is-fullwidth is-info is-light"
                                expanded
                                @click="SET_EMPTY">
                                {{ $t("common.buttons.cancel") }}
                            </b-button>
                        </div>
                        <div class="column">
                            <b-button
                                expanded
                                native-type="submit"
                                class="is-success is-light">
                                {{ $t("admin.users.administrators.modal.edit.buttons.save") }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </template>
            <LLoading
                :active="isLoading"
                :is-full-page="false">
            </LLoading>
        </ModalBox>

        <PasswordModal
            :is-active.sync="isPasswordModalActive"
            :is-loading="isPasswordLoading"
            :password.sync="password"
            @submit="changePassword"
            @closed="emptyPasswordState">
        </PasswordModal>
    </div>
</template>

<script>
  import PasswordModal from "@/components/Admin/Users/common/PasswordModal";
  import AdminProfile from "@/components/Common/Admin/AdminProfile";
  import Permissions from "@/components/Common/Permissions.vue";
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import {
    CREATE_ADMINISTRATOR,
    EDIT_ADMINISTRATOR,
    EDIT_PASSWORD,
    GET_ADMINISTRATORS
  } from "@core/store/action-constants";
  import {
    SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE,
    UPDATE_ADMINISTRATORS_EDIT_FORM,
    UPDATE_MODAL,
    SET_EMPTY
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import _cloneDeep from "lodash/cloneDeep";
  import { mapActions, mapMutations, mapState } from "vuex";
  import LLoading from "@/components/Common/LLoading";
  import { actionWithToast } from "@/helpers/actionWithToast";

  export default {
    name: "AdministratorsEditModal",

    components: {
      LLoading,
      ModalBox,
      Permissions,
      AdminProfile,
      PasswordModal
    },

    data () {
      return {
        localForm: {}
      };
    },

    computed: {
      ...mapState("admin/users/administrators/edit", [
        "administratorId",
        "form"
      ]),

      ...mapFields("admin/password", {
        fields: ["isPasswordModalActive", "password"],
        base: "modal",
        action: UPDATE_MODAL
      }),

      isEdit () {
        return this.administratorId != null;
      },

      isActive: {
        get () {
          return this.$store.state.admin.users.administrators.edit.isModalActive;
        },

        set (value) {
          this.SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE(value);
        }
      },

      isLoading () {
        const namespace = "admin/users/administrators/edit";
        return this.$wait(`${ namespace }/${ EDIT_ADMINISTRATOR }`) ||
          this.$wait(`${ namespace }/${ CREATE_ADMINISTRATOR }`);
      },

      isPasswordLoading () {
        return this.$wait(`admin/password/${ EDIT_PASSWORD }`);
      }
    },

    methods: {
      ...mapActions("admin/users/administrators", [
        GET_ADMINISTRATORS
      ]),

      ...mapActions("admin/users/administrators/edit", [
        EDIT_ADMINISTRATOR,
        CREATE_ADMINISTRATOR
      ]),

      ...mapActions("admin/password", [
        EDIT_PASSWORD
      ]),

      ...mapMutations("admin/users/administrators/edit", [
        SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE,
        UPDATE_ADMINISTRATORS_EDIT_FORM,
        SET_EMPTY
      ]),

      ...mapMutations("admin/password", {
        emptyPasswordState: SET_EMPTY
      }),

      openPasswordModal () {
        this.isPasswordModalActive = true;
      },

      close () {
        this.SET_EMPTY();
        this.localForm = {};
      },

      async changePassword () {
        await actionWithToast(this.EDIT_PASSWORD("admin"), "admin.users.common.modal.password.messages");
        this.emptyPasswordState();
      },

      async onSubmit () {
        const namespace = "admin.users.administrators.modal.edit.messages";
        const { isEdit, form: { login, email } } = this;
        const dataSet = {
          true: { action: this.EDIT_ADMINISTRATOR, name: "profileChanged" },
          false: { action: this.CREATE_ADMINISTRATOR, name: "profileCreated" }
        };

        await actionWithToast(
          dataSet[isEdit].action(),
          `${ namespace }.${ dataSet[isEdit].name }`,
          {},
          { login, email }
        );
        this.close();
      }
    },

    watch: {
      administratorId (value) {
        if (value) {
          this.localForm = _cloneDeep(this.form);
        }
      },

      isActive (value) {
        if (!this.isEdit && value) {
          this.localForm = _cloneDeep(this.form);
        }
      },

      localForm: {
        deep: true,
        handler (value) {
          this.UPDATE_ADMINISTRATORS_EDIT_FORM(value);
        }
      },

      isPasswordModalActive (value) {
        this.SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE(!value);
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
