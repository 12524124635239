<template>
    <CheckGroup
        v-model="_value"
        check-all
        :title="title"
        :check-list="checkList">
        <template #check="{ check, value, update }">
            <LTooltip
                :active="coloredData[check.value].length > 0"
                position="is-top"
                type="is-white">
                <template #content>
                    <div
                        :key="color.color"
                        v-for="color in coloredData[check.value]">
                        <div class="my-1">
                            <i
                                :style="{ backgroundColor: color.color }"
                                class="value-icon">
                            </i>
                            <span>
                                {{ color.name }}
                            </span>
                        </div>
                    </div>
                </template>
                <CustomCheckbox
                    :value="value"
                    class="is-small"
                    @input="update(check)">
                    <template #label>
                        <div :style="coloredLabel(check)">
                            {{ getPermissionsLabel(check.label) }}
                        </div>
                    </template>
                </CustomCheckbox>
            </LTooltip>
        </template>
    </CheckGroup>
</template>

<script>
  import CheckGroup from "./CheckGroup";
  import CustomCheckbox from "@/components/Common/Controls/CustomCheckbox.vue";

  export default {
    name: "ColoredCheckGroup",
    components: {
      CheckGroup,
      CustomCheckbox
    },

    props: {
      title: {
        type: String,
        default: null
      },

      checkList: {
        type: Array,
        default: null
      },

      value: {
        type: Array,
        default: () => []
      },

      checkAll: {
        type: Boolean,
        default: false
      },

      // * Список чекбоксов с цветом - {
      // *  [value]: {
      // *     color: string
      // *     ...value
      // *   }
      // * }
      coloredData: {
        type: Object,
        default: null
      }
    },

    computed: {
      _value: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit("input", value);
        }
      }
    },

    methods: {
      coloredLabel (check) {
        const { coloredData } = this;
        const percent = 100 / coloredData[check.value].length;
        const colors = coloredData[check.value].map(({ color }, i) => {
          const start = percent * i + "%";
          const end = (percent - 5) * (i + 1) + "%";
          return `${ color } ${ start } ${ end }`;
        });
        const style = {
          "border-radius": "5px",
          "padding": "4px",
          "min-width": "300px"
        };

        if (colors.length) {
          style.background =
            colors.length >= 2
              ? `linear-gradient(to right, ${ colors.join(", ") })`
              : colors[0];
        }
        return style;
      },

      getPermissionsLabel (name) {
        // * Заменяем точку на символ нижнего подчеркивания
        const key = `common.permissions.${ name.replace(/\./g, "_") }`;
        return this.$te(key) ? this.$t(key).toUpperCase() : name;
      }
    }
  };
</script>

<style lang="scss" scoped>
    .value-icon {
        padding: 0 8px;
        margin-right: 6px;
        border-radius: 50%;
    }
</style>