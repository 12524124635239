<template>
    <BlockBox>
        <template #title>
            <CustomCheckbox
                v-if="checkAll"
                :value="isSelectedAll"
                :indeterminate="isIndeterminate"
                @input="selectAll">
            </CustomCheckbox>
            <h5
                class="title is-6 has-text-weight-medium"
                :class="{'is-clickable': checkAll}"
                @click="selectAll">
                {{ title }}
            </h5>
        </template>
        <div class="row-grid row-grid--checkbox-cover">
            <div
                :key="check.value"
                v-for="(check) in checkList">
                <slot
                    :check="check"
                    :value="isSelected(check)"
                    :update="update"
                    name="check">
                    <CustomCheckbox
                        :value="isSelected(check)"
                        class="is-small"
                        @input="update(check)">
                        <template #label>
                            <div>
                                {{ check.label }}
                            </div>
                        </template>
                    </CustomCheckbox>
                </slot>
            </div>
        </div>
    </BlockBox>
</template>

<script>
  import BlockBox from "./BlockBox";
  import CustomCheckbox from "@/components/Common/Controls/CustomCheckbox.vue";
  import _intersectionBy from "lodash/intersectionBy";

  export default {
    name: "CheckGroup",
    components: {
      BlockBox,
      CustomCheckbox
    },

    props: {
      title: {
        type: String,
        default: null
      },

      // * Полный список чекбоксов в формате [
      // *  {
      // *   label: string,
      // *   value: string
      // *  }
      // * ]
      checkList: {
        type: Array,
        default: null
      },

      // * Список выбранных чекбоксов
      value: {
        type: Array,
        default: () => []
      },

      checkAll: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      isSelectedAll () {
        return _intersectionBy(this.checkList, this.value, "value").length === this.checkList.length;
      },

      isIndeterminate () {
        return this.value.length > 0 && !this.isSelectedAll;
      }
    },

    methods: {
      selectAll () {
        if (this.isSelectedAll) {
          this.$emit("input", []);
        } else {
          this.$emit("input", this.checkList);
        }
      },

      update (check) {
        const newChecks = this.isSelected(check)
          ? this.value.filter(item => item.value !== check.value)
          : [ ...this.value || [], check ];
        this.$emit("input", newChecks);
      },

      isSelected (check) {
        return !!this.value?.filter(item => item.value === check.value)?.length;
      }
    }
  };
</script>

<style lang="scss" scoped>
    .row-grid {
        &--checkbox-cover {
            grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
        }
    }

    .line-item {
        max-width: 25%;
        padding-top: 3px;
        padding-bottom: 3px;
    }
</style>