<template>
    <SectionWrapper
        :action-button-options="actionButtonOptions"
        @action="openCreateModal">
        <template #sticky>
            <AdministratorsFilters></AdministratorsFilters>
        </template>
        <AdministratorsTable></AdministratorsTable>
        <AdministratorsEditModal></AdministratorsEditModal>
    </SectionWrapper>
</template>

<script>
  import AdministratorsEditModal from "@/components/Admin/Users/AdministratorsTab/AdministratorsEditModal";
  import AdministratorsFilters from "@/components/Admin/Users/AdministratorsTab/AdministratorsFilters.vue";
  import AdministratorsTable from "@/components/Admin/Users/AdministratorsTab/AdministratorsTable.vue";
  import SectionWrapper from "@/components/Common/SectionWrapper";
  import { SET_EMPTY } from "@core/store/action-constants";
  import {
    SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE
  } from "@core/store/mutation-constants";
  import { mapActions, mapMutations } from "vuex";

  export default {
    name: "UsersAdministrators",
    components: {
      SectionWrapper,
      AdministratorsFilters,
      AdministratorsTable,
      AdministratorsEditModal
    },

    computed: {
      actionButtonOptions () {
        return {
          permissions: ["ADMIN.CREATE"],
          label: this.$t("admin.users.administrators.filters.buttons.addAdmin")
        };
      }
    },
    methods: {
      ...mapActions("admin/users/administrators", [
        SET_EMPTY
      ]),

      ...mapMutations("admin/users/administrators", {
        SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE: `edit/${ SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE }`
      }),

      openCreateModal () {
        this.SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE(true);
      }
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>
