<template>
    <ModalBox
        :is-active.sync="isModalActive"
        :is-loading="isLoadingModal.getPermissions"
        @after-leave="closeModal">
        <template #header>
            <h5 class="title is-5">
                {{ $t(`admin.users.administrators.modal.editRole.header.title`) }}
            </h5>
        </template>
        <template>
            <b-field :label="$t('common.entity.role.entityName')">
                <Select
                    v-model="selectedRoles"
                    :placeholder="$t('common.entity.role.entityName')"
                    :get-data="getRoles"
                    class="is-fullwidth mb-2"
                    field="name"
                    multiple>
                    <template #selected-option="{ option }">
                        <i
                            :style="{ backgroundColor: option.color }"
                            class="value-icon"></i>
                        {{ option.name }}
                    </template>
                </Select>
            </b-field>

            <div class="column px-0">
                <div class="columns is-multiline">
                    <div class="column">
                        <ColoredCheckGroup
                            :key="key"
                            v-for="(permission, key) in groupedPermissions"
                            v-model="selectedPermissions[key]"
                            check-all
                            :title="groupTitle(key)"
                            :check-list="permission"
                            :colored-data="permissionsIncludesInRoles">
                        </ColoredCheckGroup>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <b-button
                :disabled="!isAbleToSave"
                :loading="isLoadingModal.editRole"
                expanded
                type="is-success is-light"
                @click="editRole">
                {{ $t("common.buttons.save") }}
            </b-button>
            <b-button
                expanded
                type="is-danger is-light"
                :loading="isLoadingModal.editRole"
                class="mt-3"
                @click="closeModal">
                {{ $t("common.buttons.cancel") }}
            </b-button>
        </template>
    </ModalBox>
</template>

<script>
  import ColoredCheckGroup from "@/components/Common/ColoredCheckGroup";
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import Select from "@/components/Common/Select/Select.vue";
  import { Modal, ColorGenerator } from "@core/mixins";
  import {
    EDIT_ADMIN_ROLE,
    GET_PERMISSIONS,
    GET_ROLES
  } from "@core/store/action-constants";
  import { NotificationProgrammatic as Notification } from "buefy";
  import _cloneDeep from "lodash/cloneDeep";
  import { mapActions, mapState } from "vuex";
  import _groupBy from "lodash/groupBy";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import { toFixed } from "@core/filters";

  export default {
    name: "AdministratorsAddRoleModal",
    mixins: [Modal, ColorGenerator],
    components: {
      ColoredCheckGroup,
      ModalBox,
      Select
    },
    props: {
      admin: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        selectedRoles: null,
        selectedPermissions: []
      };
    },
    computed: {
      ...mapState("admin/roles", {
        permissions: state => state.permissions ?? [],
        roles: state => state.roles?.items ?? []
      }),

      isLoadingModal () {
        return {
          getPermissions: this.$wait(`admin/roles/${ GET_PERMISSIONS }`),
          editRole: this.$wait(`admin/roles/${ EDIT_ADMIN_ROLE }`)
        };
      },

      groupedPermissions () {
        return _groupBy(this.preparePermissions(this.permissions),
                        group => group?.label?.match(/\w+/));
      },

      indexedRoles () {
        return _cloneDeep(this.rolesWithColors.reduce((acc, item) => {
          acc[item.id] = item;

          return acc;
        }, {}));
      },

      computedRoles () {
        const { indexedRoles, selectedRoles } = this;
        return selectedRoles?.map(item => indexedRoles[item]).filter(Boolean) || [];
      },

      permissionsIncludesInRoles () {
        const rolesByPermission = {};
        const { computedRoles, permissions } = this;

        for (const permission of permissions) {
          rolesByPermission[permission] = computedRoles.filter(
            item => item.permissions.includes(permission)
          );
        }
        return rolesByPermission;
      },

      isConsistent () {
        return !this.inconsistentFields.length;
      },

      inconsistentFields () {
        return this.admin.permissions?.filter(permission => !this.permissions.includes(permission)) || [];
      },

      isAbleToSave () {
        return this.isConsistent;
      },

      rolesWithColors () {
        return this.roles.map(role => {
          const alphaRandom = Math.round(Math.random() * (45-10) + 10) / 100;
          return {
            ...role,
            color: this.colorGenerator(role, toFixed(alphaRandom, 2))
          };
        });
      }
    },
    methods: {
      ...mapActions("admin/roles", [
        GET_ROLES,
        GET_PERMISSIONS,
        EDIT_ADMIN_ROLE
      ]),

      async getPermissions () {
        await this.GET_PERMISSIONS();
      },

      async editRole () {
        const { id: adminId, login: adminLogin } = this.admin;
        const rolesIds = this.selectedRoles;
        const permissions = Object.keys(this.selectedPermissions ?? []).length ?
          Object.values(this.selectedPermissions)
            .reduce((acc, arr) => acc.concat(arr))
            .map(({ value }) => value) : [];

        const params = { admins: [{ adminId, rolesIds, permissions }] };

        await actionWithToast(
          this.EDIT_ADMIN_ROLE(params),
          "admin.users.administrators.modal.editRole.messages.edit",
          {},
          { adminLogin }
        );
        this.closeModal();
      },

      preparePermissions (permissions) {
        return permissions.map(permission => {
          return {
            label: permission,
            value: permission
          };
        });
      },

      async getRoles () {
        await this.GET_ROLES();
        return {
          count: this.rolesWithColors.length,
          items: this.rolesWithColors
        };
      },

      groupTitle (key) {
        const namespace = `common.permissions.groupTitle.${ key }`;
        return this.$te(namespace) ? this.$t(namespace) : key.replace(/_/g, " ");
      }
    },

    watch: {
      isActive: {
        deep: true,
        immediate: true,
        async handler (value) {
          if (value) {
            if (!this.permissions.length) {
              await this.getPermissions();
            }

            const { admin: { permissions, roles, login: adminLogin, id: adminId }, inconsistentFields } = this;
            this.selectedRoles = roles.map(item => item.id);
            this.selectedPermissions = _groupBy(this.preparePermissions(permissions),
                                                group => group?.label?.match(/\w+/));

            if (!this.isConsistent) {
              Notification.open({
                message: this.$t("common.errors.RESPONSE_ERROR", {
                  error: `<br><br><p><pre><code>${
                    this.$t("admin.users.administrators.modal.editRole.messages.consistent.fail", {
                      adminLogin,
                      adminId,
                      inconsistentFields
                    })
                  }`
                }),
                position: "is-top-right",
                type: "is-danger",
                hasIcon: true
              });

              this.closeModal();
              throw new Error(`
                Права пользователя ${ adminLogin } (id: ${ adminId }) не совпадают со списком прав.
                \n
                Список несовпадающих прав: ${ inconsistentFields }`
              );
            }
          }
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .modal-card {
            max-width: 800px;
            width: 100vw;
        }
    }

    .row-grid {
        &--checkbox-cover {
            grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        }
    }

    :root {
        --hue: 0;
    }

    .line-wrapper {
        overflow: hidden;
        max-height: 6px;
        border-radius: 1rem;
    }

    .line-item {
        max-width: 25%;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .value-icon {
        padding: 6px;
        margin-right: 6px;
        border-radius: 50%;
    }
</style>
