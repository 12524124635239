<template>
    <LFilters
        :filters="filters"
        :loading="isLoading"
        @clear="SET_EMPTY('filters')"
        @confirm="confirmFilters">
        <template #filters>
            <div class="columns is-narrow is-real-mobile-flex is-flex-wrap-wrap is-align-items-flex-end">
                <FilterWrapper>
                    <b-field :label="$t('admin.users.administrators.filters.labels.nameOrLogin')">
                        <LInput
                            v-model="loginOrIntId"
                            :placeholder="$t('admin.users.administrators.filters.labels.nameOrLogin')">
                        </LInput>
                    </b-field>
                </FilterWrapper>
                <FilterWrapper>
                    <b-field :label="$t('admin.users.administrators.filters.labels.email')">
                        <LInput
                            v-model="email"
                            :placeholder="$t('admin.users.administrators.filters.labels.email')">
                        </LInput>
                    </b-field>
                </FilterWrapper>
                <FilterWrapper>
                    <b-field :label="$t('admin.users.administrators.filters.labels.skype')">
                        <LInput
                            v-model="skype"
                            :placeholder="$t('admin.users.administrators.filters.labels.skype')">
                        </LInput>
                    </b-field>
                </FilterWrapper>
                <FilterWrapper>
                    <b-field :label="$t('admin.users.administrators.filters.labels.telegram')">
                        <LInput
                            v-model="telegram"
                            :placeholder="$t('admin.users.administrators.filters.labels.telegram')"
                            clearable>
                        </LInput>
                    </b-field>
                </FilterWrapper>
                <FilterWrapper>
                    <b-field :label="$t(`admin.users.administrators.filters.labels.blocked`)">
                        <Select
                            v-model="isBlocked"
                            :get-data="getIsBlocked"
                            :placeholder="$t(`admin.users.administrators.filters.values.all`)"
                            class="white"
                            :searchable="false"
                            field="label"
                            prop="value">
                        </Select>
                    </b-field>
                </FilterWrapper>
                <FilterWrapper>
                    <b-field :label="$t(`admin.integrations.filters.labels.country`)">
                        <CountriesSelect
                            v-model="countryOfManager"
                            :placeholder="$t(`webmaster.offers.filters.labels.country`)">
                        </CountriesSelect>
                    </b-field>
                </FilterWrapper>
                <FilterWrapper>
                    <b-field :label="$t(`admin.users.administrators.filters.labels.roles`)">
                        <Select
                            v-model="role"
                            :get-data-vuex="getRoles"
                            :placeholder="$t(`admin.users.administrators.filters.labels.roles`)"
                            class="fixed"
                            marquee>
                        </Select>
                    </b-field>
                </FilterWrapper>
                <Permissions :permissions="['ADMIN.ADD_OR_EDIT_PERMISSION']">
                    <FilterWrapper>
                        <b-field :label="$t(`admin.users.administrators.filters.labels.permission`)">
                            <Select
                                v-model="permission"
                                :get-data-vuex="getPermissions"
                                :placeholder="$t(`admin.users.administrators.filters.labels.permission`)"
                                class="white"
                                field="label"
                                prop="value"
                                marquee>
                            </Select>
                        </b-field>
                    </FilterWrapper>
                </Permissions>
            </div>
        </template>
    </LFilters>
</template>

<script>
  import CountriesSelect from "@/components/Common/Select/CountriesSelect.vue";
  import LFilters from "@/components/Common/LFilters";
  import Select from "@/components/Common/Select/Select.vue";
  import {
    SET_EMPTY,
    UPDATE_FILTERS,
    UPDATE_ADMINISTRATORS_FILTERS
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapState } from "vuex";
  import { filteredDataBySearch } from "@core/helpers/filteredDataBySearch";
  import { GET_ADMINISTRATORS, GET_PERMISSIONS, GET_ROLES_LIST } from "@core/store/action-constants";
  import Permissions from "@/components/Common/Permissions.vue";

  export default {
    name: "AdministratorsFilters",
    components: {
      Permissions,
      CountriesSelect,
      LFilters,
      Select
    },

    computed: {
      ...mapState("admin/roles", {
        formattedPermissions ({ permissions }) {
          return permissions?.map(el => ({ value: el, label: this.getPermissionsLabel(el) }));
        }
      }),

      ...mapFields("filtersConfirm", {
        fields: ["loginOrIntId", "isBlocked", "permission", "email", "skype", "telegram", "countryOfManager", "role"],
        base: "filters",
        action: UPDATE_FILTERS
      }),

      ...mapState("admin/users/administrators/rolesList", {
        rolesList: state => state.roles
      }),

      ...mapState("admin/users/administrators", {
        filters: ({ filters }) => filters
      }),

      isLoading () {
        return this.$wait(`admin/users/administrators/${ GET_ADMINISTRATORS }`);
      }
    },

    methods: {
      ...mapActions("admin/users/administrators", {
        GET_ADMINISTRATORS,
        SET_EMPTY,
        UPDATE_ADMINISTRATORS_FILTERS
      }),

      ...mapActions("admin/users/administrators/rolesList", { GET_ROLES_LIST }),
      ...mapActions("admin/roles", { GET_PERMISSIONS }),

      getPermissionsLabel (name) {
        // * Заменяем точку на символ нижнего подчеркивания
        const key = `common.permissions.${ name.replace(/\./g, "_") }`;
        return this.$te(key) ? this.$t(key) : name;
      },

      async getRoles (label) {
        if (!this.rolesList.items) {
          await this.GET_ROLES_LIST();
        }
        if (label) {
          return filteredDataBySearch(this.rolesList.items, label);
        } else {
          return this.rolesList;
        }
      },

      getIsBlocked () {
        const isBlocked = [
          { value: true, label: this.$t("admin.users.administrators.filters.values.blocked") },
          { value: false, label: this.$t("admin.users.administrators.filters.values.notBlocked") }
        ];
        return {
          items: isBlocked,
          count: isBlocked.length
        };
      },

      confirmFilters ({ filters }) {
        this.UPDATE_ADMINISTRATORS_FILTERS(filters);
        this.GET_ADMINISTRATORS();
      },

      async getPermissions (label = "") {
        if (this.formattedPermissions.length === 0) {
          await this.GET_PERMISSIONS();
        }
        if (label) {
          return filteredDataBySearch(this.formattedPermissions, label, ["label"]);
        } else {
          return { items: this.formattedPermissions, count: this.formattedPermissions.length };
        }
      }
    }
  };
</script>

<style scoped lang="scss">
    @media screen and (max-width: 540px){
        .reset-filter-button{
            margin-top: 30px;
            text-align: end;
        }
    }
</style>
